import ErrorSection from '@/components/integrated/error-section/error-section';
import { GenericErrorSectionProperties } from './generic-error-section.types';

const GenericErrorSection = ({
  title,
  description,
  links,
  backgroundImage,
  sectionAriaLabel,
}: GenericErrorSectionProperties) => {
  return (
    <ErrorSection
      image={backgroundImage}
      title={title}
      description={description}
      links={links}
      sectionAriaLabel={sectionAriaLabel}
    />
  );
};

export default GenericErrorSection;
