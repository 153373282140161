import { GetServerSidePropsContext } from 'next';
import { VARIANT } from '@/components/data-display/icon/icon.types';
import ContentfulProvider from '../../providers/contentful/contentful.provider';
import { genericErrorMessageContentfulQuery } from './generic-error-section.query';
import {
  GenericErrorSectionProperties,
  GenericErrorSectionContentfulResponse,
} from './generic-error-section.types';
import { handleImageDescription } from '../../utils/contentful-data-formatters/contentful-data-formatters';

export const genericErrorSectionHandler = async ({
  req: { market },
}: GetServerSidePropsContext): Promise<GenericErrorSectionProperties> => {
  const contentfulRequest = new ContentfulProvider(
    market,
  ).makeContentfulRequest<GenericErrorSectionContentfulResponse>({
    query: genericErrorMessageContentfulQuery,
  });

  const contentfulResponse = await contentfulRequest;

  const pageErrorContent = contentfulResponse.data.pageErrorCollection.items[0];

  const links = pageErrorContent?.pageLinksCollection?.items.map(link => ({
    label: link.label ?? '',
    href: link.url ?? '',
    icon: (link.developerRef as VARIANT) ?? '', // TODO: when developerRef is not defined then the icon will not be displayed
  }));

  return {
    title: pageErrorContent?.title ?? '',
    description: pageErrorContent?.description ?? '',
    sectionAriaLabel: pageErrorContent?.sectionAriaLabel ?? '',
    backgroundImage: {
      src: pageErrorContent?.pageImage.url ?? '',
      alt: handleImageDescription(
        pageErrorContent?.pageImage.title,
        pageErrorContent?.pageImage.description,
      ),
    },
    links: links ?? [],
  };
};
