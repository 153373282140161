import { GetServerSideProps } from 'next';
import { GenericErrorSectionProperties } from '../../modules/generic-error-section/generic-error-section.types';
import { genericErrorSectionHandler } from '../../modules/generic-error-section/generic-error-section.handler';
import GenericErrorSection from '../../modules/generic-error-section/generic-error-section';
import logger, { formatErrorForLogging } from '../../utils/logger';

export type ErrorPageProperties = {
  genericErrorSectionProperties: GenericErrorSectionProperties;
};

const ErrorPage = ({ genericErrorSectionProperties }: ErrorPageProperties) => {
  return <GenericErrorSection {...genericErrorSectionProperties} />;
};

export const getServerSideProps: GetServerSideProps<
  ErrorPageProperties
> = async context => {
  logger.error('Generic error caught while loading the page.', {
    resolvedUrl: context.resolvedUrl,
    url: context.req.url,
    method: context.req.method,
    error: formatErrorForLogging(context.err),
  });

  const [genericErrorSectionProperties] = await Promise.all([
    genericErrorSectionHandler(context),
  ]);

  return {
    props: {
      genericErrorSectionProperties,
    },
  };
};

export default ErrorPage;
