import classNames from 'classnames';
import { CustomLink as Link } from '@/components/custom-link';
import NextImage from '@/components/data-display/image';
import Icon from '@/components/data-display/icon/icon';
import Section from '@/components/integrated/section';
import { useClientSideMarketContext } from '@/context/client-side-market/market-context';
import { isAviosOpco } from '@/utils/opco-utils';
import { Heading } from '@alto-avios/alto-ui';
import { ErrorSectionProperties } from './error-section.types';
import styles from './error-section.module.scss';

const ErrorSection = ({
  image,
  title,
  description,
  links,
  sectionAriaLabel,
}: ErrorSectionProperties) => {
  const { opCoId } = useClientSideMarketContext();
  const isAvios = isAviosOpco(opCoId);

  return (
    <Section className={classNames(styles['custom-error-page'])} isFullBleed>
      {image ? (
        <div className={styles['custom-error-page__image-container']}>
          <div className={styles['custom-error-page__image']}>
            <NextImage src={image?.src} alt={image.alt} fill />
          </div>
          {isAvios ? (
            <div className={styles['custom-error-page__text-container']}>
              <Heading
                as="h1"
                size="xl"
                textAlign="center"
                foregroundColor="default"
              >
                {title}
              </Heading>
              <p>{description}</p>
            </div>
          ) : (
            <div className={styles['custom-error-page__card-container']}>
              <Section ariaLabel={sectionAriaLabel}>
                <div className={styles['custom-error-page__card']}>
                  <h2>{title ?? ''}</h2>
                  <p>{description}</p>
                  {links.length > 0 ? (
                    <ul className={styles['custom-error-page__card-list']}>
                      {links.map(link => (
                        <li
                          key={link.href}
                          className={
                            styles['custom-error-page__card-list-item']
                          }
                        >
                          <Link
                            href={link.href}
                            className={styles['custom-error-page__card-link']}
                          >
                            {link.icon && (
                              <Icon
                                variant={link.icon}
                                className={
                                  styles['custom-error-page__card-link-icon']
                                }
                              />
                            )}
                            {link.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
              </Section>
            </div>
          )}
        </div>
      ) : null}
    </Section>
  );
};

export default ErrorSection;
